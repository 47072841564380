<template>
  <div class="card" :style="settings.fg">
    <div class="card-body" v-if="!isLoading">
      <div class="row px-2 py-1">
        <div class="mb-3">
          <span class="">
          Popular Services 
          <i
            v-b-tooltip.hover.right
            title="Overview of the most popular and top used services"
            class="bi bi-info-circle text-muted">
          </i>
        </span>
        </div>
        <TopServicesChart :options="chartOptions" :series="series"/>
      </div>
    </div>
    <div v-else >
      <b-card :style="settings.fg">
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
      </b-card>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TopServicesChart from '../../charts/TopServicesChart.vue'
const MetricsModule = createNamespacedHelpers('metrics')
const SettingsModule = createNamespacedHelpers("setting");

export default {
  name: 'TopServicesCard',
  components: {
    TopServicesChart
  },
  data(){
    return {
      isLoading: false,
    }
  },
  computed: {
    ...MetricsModule.mapGetters(['selectTopServiceChart']),
    ...SettingsModule.mapGetters(['selectUserSettings']),

    series(){
      return this.selectTopServiceChart.series || []
    },

    chartOptions(){
      return this.selectTopServiceChart.options || {}
    },
    settings() {
      return this.selectUserSettings;
    },
  },
  methods: {
    ...MetricsModule.mapActions(['fetchTopServicesChart']),
  },
  async mounted() {
    this.isLoading = true
    await this.fetchTopServicesChart()
    this.isLoading = false
  }
}
</script>