<template>
  <div>
    <apexchart height="300" type="bar" :options="options" :series="series"></apexchart>
  </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
export default {
  name: 'SpotlightChart',
  props: {
    options: {
      type: Object,
    },
    series: {
      type: Object,
    }
  },
  components: {
    apexchart: VueApexCharts
  }
}
</script>