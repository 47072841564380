<template>
  <div class="row">
    <div class="col-12">
      <span class="">
        Summary <br />
        <small class="text-muted">
          Overview of the current environment
        </small>
      </span>

      <div class="mt-2">
        <div class="row d-flex align-items-center">
          <div class="col-2">
            <span class="badge summary-badge-temp text-center">
              <i
                class="bi bi-thermometer-half"
                style="font-size: 30px"
              >
              </i>
            </span>
          </div>
          <div class="col text-center mt-4">
            <span class="mr-auto temperature">
              <div :class="['h4', themeMode === 'dark' ? 'text-muted' : 'text-dark']">
                <AnimatedNumber
                  prefix=""
                  suffix=" °F"
                  separator=","
                  :startAmount="0"
                  :endAmount="metrics.temperature || 0"
                  :duration="1"
                  decimalSeparator="."
                  :autoinit="true"
                />
              </div>
              <small class="text-muted">Temperature</small>
            </span>
          </div>
          <div class="col text-center mt-4">
            <span>
              <div :class="['h4', themeMode === 'dark' ? 'text-muted' : 'text-dark']">
                <AnimatedNumber
                  prefix=""
                  suffix=" %"
                  separator=","
                  :startAmount="0"
                  :endAmount="metrics.humidity || 0"
                  :duration="1"
                  decimalSeparator="."
                  :autoinit="true"
                />
              </div>
              <small class="text-muted">Humidity</small>
            </span>
          </div>
        </div>
      </div>

      <div class="mt-2">
        <div class="row d-flex align-items-center">
          <div class="col-2">
            <span class="badge summary-badge-wind text-center">
              <i class="bi bi-wind" style="font-size: 30px"></i>
            </span>
          </div>
          <div class="col text-center mt-4">
            <span class="mr-auto">
              <div :class="['h4', themeMode === 'dark' ? 'text-muted' : 'text-dark']">
                <AnimatedNumber
                  :startAmount="0"
                  :endAmount="windSpeed.value || 0"
                  :duration="1"
                  separator="1"
                  :suffix='` mph`'
                  decimalSeparator="."
                  :autoinit="true"
                />
              </div>
              <small class="text-muted">Wind Speed</small>
            </span>
          </div>
          <div class="col text-center mt-4">
            <span>
              <div :class="['h4', themeMode === 'dark' ? 'text-muted' : 'text-dark']">
                {{ windDirection }}
              </div>
              <small class="text-muted">Wind Direction</small>
            </span>
          </div>
        </div>
      </div>

      <div class="shortForecast mt-3 text-muted">
        Short Forecast: <small class="text-success">{{ metrics.shortForecast }}</small>
      </div>

      <div class="form-group mt-3">
          <b-button variant="primary">
            View More Metrics
          </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import AnimatedNumber from "vue3-autocounter";
import { createNamespacedHelpers } from "vuex";
const MetricsModule = createNamespacedHelpers("metrics");

export default {
  name: "SummarySection",
  props: ['themeMode'],
  components: {
    AnimatedNumber,
  },
  computed: {
    ...MetricsModule.mapGetters(["selectMetrics"]),

    metrics() {
      return this.selectMetrics || {};
    },
    windSpeed() {
      let value = {};
      if (this.selectMetrics) {
        const windSpeedParts = this.selectMetrics.windSpeed.split(" ");
        value = {
          value: parseInt(windSpeedParts[0]),
          suffix: windSpeedParts[1],
        };
      }
      return value;
    },
    windDirection(){
      return this.selectMetrics && this.selectMetrics.windDirection || '- - -'
    }
  },
};
</script>

<style scoped lang="scss">
.summary-badge-temp {
  border-radius: 30px;
  opacity: 0.9;
  background-color: #f2e6ff;
  i {
    color: #6300cc;
    font-weight: bold;
  }
}
.summary-badge-wind {
  border-radius: 30px;
  opacity: 0.9;
  background-color:   #ffd6cc;
  i {
    color: #cc2900;
    font-weight: bold;
  }
}
</style>
