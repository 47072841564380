<template>
  <div class="row">
    <div class="col-12">
      <SpotlightCard/>
    </div>
    <div class="col-md-5 col-sm-12 col-xs-12 mt-4">
      <TopServicesCard/>
    </div>
    <div class="col-md-7 col-sm-12 col-xs-12 mt-4">
      <ServicesUsageChart/>
    </div>
    <!-- <div class="col-md-12 col-sm-12 col-xs-12 mt-4">
      <ServicesUsageChart/>
    </div> -->
  </div>
</template>
<script>
import SpotlightCard from './cards/SpotlightCard.vue'
import TopServicesCard from './cards/TopServicesCard.vue'
import ServicesUsageChart from './cards/ServicesUsageCard.vue'
import { createNamespacedHelpers } from 'vuex'
const ReportsModule = createNamespacedHelpers('report')
const MetricsModule = createNamespacedHelpers('metrics')

export default {
  name: 'DashboardContents',
  components: {
    SpotlightCard,
    TopServicesCard,
    ServicesUsageChart,
  },
  computed: {
    ...ReportsModule.mapGetters(['selectCharts']),

    summary(){
      return this.selectCharts.find(chart => chart.name === 'SUMMARY_CHART')
    },
  },
  methods: {
    ...ReportsModule.mapActions([
      'loadUserReports',
      'setSelectedChart', 
      'fetchSummaryChartReport'
    ]),
    ...MetricsModule.mapActions(['fetchMetrics'])
  },
  async mounted() {
    await this.loadUserReports()
    await this.fetchMetrics()
  }
}
</script>