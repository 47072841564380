<template>
  <div>
    <apexchart
      type="area"
      height="250"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
export default {
  name: "ServicesUsageChart",
  props: ["series", "options"],
  components: {
    apexchart: VueApexCharts,
  },
};
</script>