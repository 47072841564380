<template>
  <div class="card" :style="settings.fg">
    <div class="card-body" v-if="!isLoading">
      <div class="row ">
        <div class="mb-3">
          <span class="">
          Monthly Service Usage
          <i
            v-b-tooltip.hover.right
            title="Number of usage for all services each month, year to date."
            class="bi bi-info-circle text-muted">
          </i>
        </span>
        </div>
        <ServicesUsageChart :options="chartOptions" :series="series"/>
      </div>
    </div>
    <div v-else >
      <b-card :style="settings.fg">
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
      </b-card>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ServicesUsageChart from '../../charts/ServicesUsageChart.vue'
const MetricsModule = createNamespacedHelpers('metrics')
const SettingsModule = createNamespacedHelpers("setting");

export default {
  name: 'ServicesUsageCard',
  components: {
    ServicesUsageChart
  },
  data(){
    return {
      isLoading: false,
    }
  },
  computed: {
    ...MetricsModule.mapGetters(['selectServicesUsageChart']),
    ...SettingsModule.mapGetters(['selectUserSettings']),

    series(){
      return this.selectServicesUsageChart.series || []
    },

    chartOptions(){
      return this.selectServicesUsageChart.options || {}
    },
    settings() {
      return this.selectUserSettings;
    },
  },
  methods: {
    ...MetricsModule.mapActions(['fetchServicesUsageChart']),
  },
  async mounted() {
    this.isLoading = true
    await this.fetchServicesUsageChart()
    this.isLoading = false
  }
}
</script>