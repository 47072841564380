<template>
  <div class="card" :style="{...settings.fg}">
    <div class="card-body">
      <div class="row px-2 py-2">
        <div class="col-md-4 col-sm-12">
          <SpotlightSummary :themeMode="themeMode" />
        </div>
        <div class="col-md-8 col-sm-12" v-if="!isLoading">
          <ChartHeaders :title="''" @ChartFiltersModelShow="ChartFiltersModelShow"/>
          <SpotlightChart
            :series="series"
            :options="options"
           />
        </div>
        <!-- skeleton loader --> 
        <div class="col-md-8 col-sm-12" v-if="isLoading" >
          <b-row>
            <b-col cols="12">
              <b-skeleton animation="wave" width="100%"></b-skeleton>
              <b-skeleton animation="wave" width="95%"></b-skeleton>
              <b-skeleton animation="wave" width="100%"></b-skeleton>
              <b-skeleton animation="wave" width="85%"></b-skeleton>
            </b-col>
            <b-col cols="12" class="mt-3">
              <b-skeleton animation="wave" width="100%"></b-skeleton>
              <b-skeleton animation="wave" width="90%"></b-skeleton>
              <b-skeleton animation="wave" width="100%"></b-skeleton>
              <b-skeleton animation="wave" width="90%"></b-skeleton>
            </b-col>
            <b-col cols="12" class="mt-3">
              <b-skeleton animation="wave" width="100%"></b-skeleton>
              <b-skeleton animation="wave" width="100%"></b-skeleton>
              <b-skeleton animation="wave" width="100%"></b-skeleton>
              <b-skeleton animation="wave" width="100%"></b-skeleton>
            </b-col>
          </b-row>
        </div>
        <!-- skeleton loader --> 
      </div>
    </div>
    <hr class="text-muted bg-white" style="margin-top: -25px">
    <div class="card-body">
      <SpotlightSnapshots />
    </div>
  </div>
</template>
<script>
import ChartHeaders from '../../charts/chart-options/ChartHeaders.vue'
import SpotlightChart from "../../charts/SpotlightChart.vue"
import SpotlightSummary from './SpotlightSummary.vue'
import SpotlightSnapshots from './SpotlightSnapshots.vue'
import { createNamespacedHelpers } from 'vuex'

const MetricsModule = createNamespacedHelpers('metrics')
const ReportsModule = createNamespacedHelpers('report')
const SettingsModule = createNamespacedHelpers("setting");

export default {
  name: "SpotlightCard",
  components: {
    ChartHeaders,
    SpotlightChart,
    SpotlightSummary,
    SpotlightSnapshots
  },
  props: ['summary'],
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...MetricsModule.mapGetters(['selectSpotlightChart']),
    ...SettingsModule.mapGetters(['selectUserSettings', 'selectApplicationSettings']),

    series() {
      return this.selectSpotlightChart.series || []
    },
    options(){
      return this.selectSpotlightChart.options || {}
    },
    settings() {
      return this.selectUserSettings;
    },
    themeMode() {
      return this.selectApplicationSettings.theme
    },
    
    // series: function() {
    //   return [
    //     {
    //       name: "series1",
    //       data: [31, 40, 28, 51, 42, 109, 100, 38, 47, 58, 77, 84]
    //     },
    //     {
    //       name: "series2",
    //       data: [100, 87, 12, 31, 42, 64, 55, 20, 97, 28, 37, 104]
    //     },
    //     {
    //       name: "Series3",
    //       data: [44, 55, 41, 64, 22, 43, 21, 80, 22, 100, 56, 70]
    //     }
    //   ];
    // },
    // chartOptions: function() {
    //   return {
    //     dataLabels: {
    //       enabled: false
    //     },
    //     chart: {
    //       id: "chart1",
    //       toolbar: {
    //         show: false
    //       }
    //     },

    //     stroke: {
    //       width: 0.2,
    //       colors: ["#74fb00"],
    //       curve: "smooth"
    //     },

    //     legend: {
    //       position: 'top',
    //       horizontalAlign: 'right', 
    //     },

    //     dropShadow: {
    //       enabled: true,
    //       top: 0,
    //       left: 0,
    //       blur: 3,
    //       opacity: 0.5
    //     },

    //     theme: {
    //       monochrome: {
    //         enabled: true,
    //         shadeTo: "light",
    //         shadeIntensity: 0.65
    //       }
    //     },

    //     grid: {
    //       yaxis: {
    //         lines: {
    //           show: false,
    //           offsetX: 0,
    //           offsetY: 0
    //         }
    //       }
    //     },

    //     xaxis: {
    //       categories: [
    //         "Jan",
    //         "Feb",
    //         "Mar",
    //         "Apr",
    //         "May",
    //         "Jun",
    //         "Jul",
    //         "Aug",
    //         "Sep",
    //         "Oct",
    //         "Nov",
    //         "Dec"
    //       ]
    //     }
    //   };
    // }
  },
  methods: {
    ...ReportsModule.mapActions(['fetchSummaryChartReport', 'setSelectedChart']),
    ...MetricsModule.mapActions(['fetchSpotlightMetrics']),

    async ChartFiltersModelShow(){
      this.setSelectedChart({
        name: 'SPOTLIGHT_CHART',
        series: this.series,
        options: this.options,
      })
    },
  },
  async mounted() {
    await this.fetchSpotlightMetrics({ limit: 12 })
  }
};
</script>
