<template>
  <ion-page>
    <ion-header>
      <ion-toolbar></ion-toolbar>
    </ion-header>
    <ion-content :style="settings.bg">
      <div class="container">
        <div class="dash-wrapper mt-4">
          <!-- dashboard headers -->
          <div class="dash-header">
            
          </div>
          <!-- dashboard contents -->
          <div class="dash-contens">
            <DashboardContents/>
          </div>
          <!-- dashboard footer -->
          <div class="dash-footer">

          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage, 
  IonHeader, 
  IonContent, 
  IonToolbar 
} from '@ionic/vue'
import { createNamespacedHelpers } from 'vuex'
import DashboardContents from '../modules/dashboard/DashboardContents.vue'
const SettingsModule = createNamespacedHelpers('setting')
const SpeechRecognitionModule = createNamespacedHelpers('speechRecognition')

export default {
  name: 'DashboardPage',
  components: {
    IonPage, 
    IonHeader, 
    IonContent, 
    IonToolbar,
    DashboardContents
  },
  computed: {
    ...SettingsModule.mapGetters(['selectUserSettings']),

    settings(){
      return this.selectUserSettings
    },
  },
  methods: {
    ...SpeechRecognitionModule.mapActions(['fetchSpeechRecognitionSettings']),
  },
  async mounted() {
    await this.fetchSpeechRecognitionSettings()
  }
}
</script>