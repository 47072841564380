<template>
  <div class="d-flex align-items-center">
    <i :class="item.icon" style="font-size: 35px;"></i>
    <span style="margin-left: 20px" class="mr-auto"
      ><small class="text-muted"> {{ item.displayName }}</small> <br />
      <div class="h5">
        <AnimatedNumber
          :startAmount="0"
          :endAmount="itemValue || 0"
          :duration="1"
          separator="1"
          decimalSeparator="."
          :autoinit="true"
        /></div
    ></span>
  </div>
</template>
<script>
import AnimatedNumber from "vue3-autocounter";
import { createNamespacedHelpers } from "vuex";
const ReportsModule = createNamespacedHelpers("report");

export default {
  name: "SnapshotItem",
  props: ["item"],
  components: {
    AnimatedNumber,
  },
  computed: {
    ...ReportsModule.mapGetters(["selectReports", "selectReportsByKind"]),

    itemValue() {
      let value = 0;
      if (this.item) {
        value = this.selectReportsByKind(this.item.kind);
      }
      return value;
    },
  },
  methods: {
    ...ReportsModule.mapActions(["fetchReportByKind"]),
  },
  mounted() {
    if (this.item) {
      this.fetchReportByKind(this.item.kind);
    }
  },
};
</script>
