<template>
  <div class="row px-2">
    <div class="col-md-3 col-sm-12" v-for="item in widgets" :key="item._id">
      <SnapshotItem :item="item"/>
    </div>
    <!-- <div class="col-3">
      <div class="d-flex align-items-center">
        <b-avatar src="https://placekitten.com/300/300" square></b-avatar>
        <span style="margin-left: 20px" class="mr-auto"
          ><small> Messages</small> <br />
          <div class="h5">4,536</div></span
        >
      </div>
    </div>
    <div class="col-3">
      <div class="d-flex align-items-center">
        <b-avatar src="https://placekitten.com/300/300" square></b-avatar>
        <span style="margin-left: 20px" class="mr-auto"
          ><small> Messages</small> <br />
          <div class="h6">4,536</div></span
        >
      </div>
    </div>
    <div class="col-3">
      <div class="d-flex align-items-center">
        <b-avatar src="https://placekitten.com/300/300" square></b-avatar>
        <span style="margin-left: 20px" class="mr-auto"
          ><small> Messages</small> <br />
          <div class="h5">4,536</div></span
        >
      </div>
    </div>
    <div class="col-3">
      <div class="d-flex align-items-center">
        <b-avatar src="https://placekitten.com/300/300" square></b-avatar>
        <span style="margin-left: 20px" class="mr-auto"
          ><small> Conversations</small> <br />
          <div class="h6">4,536</div></span
        >
      </div>
    </div> -->
  </div>
</template>
<script>
import SnapshotItem from './SnapshotItem.vue'
import { createNamespacedHelpers } from 'vuex'
const ReportsModule = createNamespacedHelpers('report')
export default {
  name: 'SpotlightSnapshots',
  components: { SnapshotItem },
  computed: {
    ...ReportsModule.mapGetters(['selectWidgets']),

    widgets(){
      return this.selectWidgets.filter(value => value.enabled)
    }
  }
}
</script>
